<template>
  <v-container fluid fill-height class="bg-texture">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-form ref="form" v-model="valid" @submit="login">
          <v-card :loading="loading_status"  tile class="mx-auto bglogin" max-width="344" flat>
            <v-card-title class="d-flex justify-center m-2">
              <v-img contain src="../../assets/logo.png"></v-img>
            </v-card-title>
            <v-card-text class="mt-6">
              <v-text-field rounded light background-color="white" v-if="storelst.length === 0"
                v-model="credential.user_email" :rules="f_required" solo required
                prepend-inner-icon="mdi-account-outline" hide-details>
              </v-text-field>
              <v-text-field rounded light prepend-inner-icon="mdi-lock-outline" background-color="white"
                class="mt-3 mb-3" v-if="storelst.length === 0" v-model="credential.user_password" type="password"
                :rules="f_required" solo required hide-details>
              </v-text-field>
              <v-select rounded solo v-if="storelst.length > 0" v-model="credential.store" :items="storelst"
                label="Centro Productivo" item-text="name" item-value="store_id" height="50px" hide-details>
              </v-select>
              <v-card-actions v-if="storelst.length === 0">
                <v-checkbox v-model="accept_terms">
                  <template v-slot:label>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn text to="/about" @click.stop v-on="on" x-small>
                            Acepto los Terminos y Condiciones de uso
                          </v-btn>
                        </template>
                        Ver los terminos y condiciones de uso
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </v-card-actions>

              <v-card-actions>
                <v-btn text dark large color="warning">
                  Crear cuenta
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="!accept_terms" large color="success" type="submit" @click="validate">
                  Continuar
                </v-btn>
              </v-card-actions>
              <AddToHomeScreen />
            </v-card-text>

          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createProfile from "../../utils/createProfile.js";
import createStore from "../../utils/createStore.js";
import AddToHomeScreen from "../../components/PwaInstall.vue";
export default {
  name: "Login",
  props: {
    nextUrl: {
      default: null,
    },
  },
  components: { AddToHomeScreen },
  data() {
    return {
      accept_terms: false,
      errors: [],
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      loading_status: false,
      credential: {
        store: null,
        user_email: null,
        user_password: "",
        token: null,
      },
      storelst: [],
      profile: createProfile(),
      company: createStore(),
    };
  },
  mounted() {
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
    //this.$store.dispatch("setCompany", this.company);
    // var getProfile = window.localStorage.getItem("sc_c");
    // if (getProfile) {
    //   var profile = JSON.parse(
    //     this.CryptoJS.AES.decrypt(getProfile, process.env.VUE_APP_QR4).toString(
    //       this.CryptoJS.enc.Utf8
    //     )
    //   );
    //   var getStore = window.localStorage.getItem("sc_s");
    //   var store = JSON.parse(
    //     this.CryptoJS.AES.decrypt(getStore, process.env.VUE_APP_QR4).toString(
    //       this.CryptoJS.enc.Utf8
    //     )
    //   );
    //   this.credential.user_email = profile.email;
    //   this.credential.token = profile.token;
    //   this.credential.store = store.code;
    //   this.$refs.form.$el.submit()
    // }
  },
  methods: {
    getLogo: function () {
      var images = require.context("../../assets/", false, /\.jpg$/);
      return images("./logo.jpg");
    },
    validate() {
      this.$refs.form.validate();
    },
    login(e) {
      e.preventDefault();
      this.loading_status = true;

      var md5 = require("md5");
      var qry = {
        user_email: this.credential.user_email,
        user_password: md5(this.credential.user_password),
        user_token: this.credential.token,
        store: this.credential.store,
      };

      var metodo = "pre_login";
      if (this.credential.store) {
        metodo = "login";
      }
      //console.log(qry);
      webserver(metodo, qry, (data) => {
        //console.log(data);
        this.loading_status = false;
        if (metodo === "login") {
          //console.log("login", data);
          this.profile.name = data.profile.user_name;
          this.profile.email = data.profile.user_email;
          this.profile.token = data.profile.token;
          this.profile.picture = data.profile.picture;
          this.profile.socialName = "WEB";
          this.profile.account = data.company.account;
          this.profile.code = data.profile.code;
          this.profile.user_access = data.profile.user_access;

          this.$store.dispatch("setProfile", this.profile);
          this.$store.dispatch("setCompany", data.company);

          window.token = data.token;
          window.profile = this.profile;
          window.company = data.company;

          const encryptedText = this.CryptoJS.AES.encrypt(
            JSON.stringify(this.profile),
            process.env.VUE_APP_QR4
          ).toString();
          window.localStorage.setItem("sc_c", encryptedText);

          const encryptedStore = this.CryptoJS.AES.encrypt(
            JSON.stringify(data.company),
            process.env.VUE_APP_QR4
          ).toString();
          window.localStorage.setItem("sc_s", encryptedStore);

          window.settings = data.settings.reduce(function (r, a) {
            r[a.tabla] = r[a.tabla] || [];
            r[a.tabla].push(a);
            return r;
          }, Object.create(null));

          this.$store.dispatch("setNavStatus", true);
          this.$store.dispatch("set_menu", true);

          if (this.nextUrl) {
            this.$router.push(this.nextUrl);
          } else {
            this.$router.push("/Welcome");
          }
        } else {
          this.storelst = data;
        }
      });
    },
    popupwindow(url, title, w, h) {
      var left = screen.width / 2 - w / 2;
      var top = screen.height / 2 - h / 2;
      return window.open(
        url,
        title,
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
      );
    },
  },
};
//background: url("bg-welcome.jpg") no-repeat center center fixed;
</script>

<style scoped>
.bg-texture {
  background: url("../../assets/supplier2.jpg") no-repeat center center fixed;

  /* background: black; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.v-text-field__slot input {
  background: white !important;
}
.bglogin {
  background: hsla(0, 27%, 98%, 0.619);
}

.btn-login {
  color: #000000;
}
</style>
